import * as React from "react";
import * as Styles from "./websites.module.sass";
import * as gear from "../lotties/gears.json";
import * as rocket from "../lotties/rocket.json";
import * as tool from "../lotties/tool.json";
import Seo from "../components/seo";
import Lottie from "react-lottie";
import { Link } from "gatsby";
import { Formik, Form, Field, ErrorMessage } from "formik";

const WebsitesPage = () => {
  const dfltStatus = { status: `notsubmitted`, msg: `` };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const gearOpt = { animationData: gear, ...defaultOptions };
  const rocketOpt = { animationData: rocket, ...defaultOptions };
  const toolOpt = { animationData: tool, ...defaultOptions };
  return (
    <>
      <div className={`container`}>
        <Seo
          title={`Разработка сайтов`}
          description={`Создадим быстрый сайт под ваши задачи.`}
        />
        <div className={`${Styles.wrapper}`}>
          <h1>Разработка сайтов</h1>
          <div className={`tile is-ancestor m-0 pt-10`}>
            <div className={`tile is-parent is-paddingless`}>
              <div className={`tile is-child is-relative ${Styles.workwrap}`}>
                <img
                  alt={`kastorskyjewelry.ru`}
                  src={`/images/kast_web_pc.jpg`}
                  type={`image/png`}
                />
              </div>
              <div className={`tile is-child is-relative ${Styles.workwrap}`}>
                <div>
                  <h2>Ювелирная мануфактура Касторский</h2>
                  <p>
                    Сайт бренда, который производит сосуды из серебра для
                    благородных напитков.
                  </p>
                </div>
                <Link to={`/websites/kastorsky`}>Подробнее</Link>
              </div>
            </div>
          </div>
          <div className={`tile is-ancestor m-0 pt-10`}>
            <div className={`tile is-parent is-paddingless ${Styles.reverse}`}>
              <div className={`tile is-child is-relative ${Styles.workwrap}`}>
                <img
                  alt={`3djew.mustbefamily.com`}
                  src={`/images/mbf_web_pc.jpg`}
                  type={`image/png`}
                />
              </div>
              <div className={`tile is-child is-relative ${Styles.workwrap}`}>
                <div>
                  <h2>3D визуализации ювелирных украшений</h2>
                  <p>
                    Сайт для демонстрации наших моушен видео анимированных
                    моделей ювелирных украшений.
                  </p>
                </div>
                <Link to={`/websites/3djew`}>Подробнее</Link>
              </div>
            </div>
          </div>
          <div className={`tile is-ancestor m-0 pt-10`}>
            <div className={`tile is-parent is-paddingless`}>
              <div className={`tile is-child is-relative ${Styles.workwrap}`}>
                <img
                  alt={`jewelry.mustbefamily.com`}
                  src={`/images/jew_web_pc.jpg`}
                  type={`image/png`}
                />
              </div>
              <div className={`tile is-child is-relative ${Styles.workwrap}`}>
                <div>
                  <h2>Презентация для ювелирных брендов</h2>
                  <p>
                    Интерактивный лендинг, демонстрирующий разные виды
                    ювелирного контента.
                  </p>
                </div>
                <Link to={`/websites/jewelry`}>Подробнее</Link>
              </div>
            </div>
          </div>{" "}
          <div className={`tile is-ancestor m-0 pt-10`}>
            <div className={`tile is-parent is-paddingless ${Styles.reverse}`}>
              <div className={`tile is-child is-relative ${Styles.workwrap}`}>
                <img
                  alt={`karlcouchon.ru`}
                  src={`/images/karl_web_pc.jpg`}
                  type={`image/png`}
                />
              </div>
              <div className={`tile is-child is-relative ${Styles.workwrap}`}>
                <div>
                  <h2>Каталог для компании "Karl Couchon"</h2>
                  <p>
                    Современный и стильный каталог ювелирной продукции для
                    компании "Karl Couchon".
                  </p>
                </div>
                <Link to={`/websites/karlcouchon`}>Подробнее</Link>
              </div>
            </div>
          </div>
          <h1 className={`${Styles.secondheader}`}>Преимущества</h1>
          <div className={`tile is-ancestor m-0 ${Styles.firstblock}`}>
            <div className={`tile is-parent is-paddingless`}>
              <div className={`tile is-child is-relative ${Styles.block}`}>
                <div className={`${Styles.animicon}`}>
                  <Lottie height={100} width={100} options={gearOpt} />
                </div>
                <h2>Качество в деталях</h2>
                <p>
                  От выбора хостинга до системы управления контентом, и от
                  создания дизайна до программирования мы продумываем все до
                  мелочей. Ваш сайт должен быть не только красивым, а еще и
                  удобным.
                </p>
              </div>
              <div className={`tile is-child is-relative ${Styles.block}`}>
                <div className={`${Styles.animicon}`}>
                  <Lottie height={100} width={100} options={rocketOpt} />
                </div>
                <h2>Очень быстрые сайты</h2>
                <p>
                  Высокая степень оптимизации сайта является одной из наших
                  основных задач. Показатель скорости загрузки влияет на
                  конверсию. Мы используем современные инструменты веб
                  разработки и немного облачной магии при создании сайта для
                  вас.
                </p>
              </div>
              <div className={`tile is-child is-relative ${Styles.block}`}>
                <div className={`${Styles.animicon}`}>
                  <Lottie height={100} width={100} options={toolOpt} />
                </div>
                <h2>Сайт должен работать</h2>
                <p>
                  Для каждого бизнеса сайт решает разные задачи. Мы гордимся
                  своим подходом не только к разработке, но и пониманию вашего
                  бизнеса, ставя себя на место владельца, исполнителя и клиента.
                  В итоге сайт вашего бренда - красивый, быстрый, удобный.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${Styles.mainlayer}`}>
        <Formik
          initialStatus={dfltStatus}
          initialValues={{ phone: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.phone) {
              errors.phone = "Поле обязательно для заполнения";
            } else if (
              !/^[\d]{10,15}$/i.test(values.phone.replace(/[^\d]/g, ""))
            ) {
              errors.phone =
                "Неизвестный формат номера, необходимо как минимум 10 цифр";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setStatus(dfltStatus);
            let formData = new FormData();
            formData.append(`phone`, values.phone.replace(/[^\d]/g, ""));
            formData.append(`issite`, true);
            fetch("/form.php", {
              method: "POST",
              body: formData,
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                }
                return Promise.reject({
                  status: `error`,
                  msg: `Упс! Что-то пошло не так. Попробуйте обновить страницу и попробовать ещё раз. Или воспользуйтесь кнопкой выше и позвоните нам!`,
                });
              })
              .then((response) => {
                setSubmitting(false);
                setStatus(response);
              })
              .catch((obj) => {
                setSubmitting(false);
                setStatus(obj);
              });
          }}
        >
          {({ isSubmitting, status }) =>
            status && status.status === `ok` ? (
              <div className={``}>{status.msg}</div>
            ) : (
              <>
                <h1>Хотите заказать разработку сайта?</h1>
                <h2>Оставьте номер и мы перезвоним.</h2>
                <Form>
                  <div>
                    <Field
                      placeholder={`+7 999 999 9999`}
                      className={`${Styles.field}`}
                      type="phone"
                      name="phone"
                    />
                    <ErrorMessage name="phone" component="div" />
                  </div>
                  <div>
                    <button
                      className={`button is-outlined is-radiusless ${
                        Styles.sendbutton
                      } ${isSubmitting ? `is-loading` : ``}`}
                      type="submit"
                    >
                      Отправить
                    </button>
                  </div>
                  {status.status === `error` ? (
                    <div className={``}>{status.msg}</div>
                  ) : (
                    <></>
                  )}
                  <div className={`${Styles.policylink}`}>
                    Нажимая кнопку отправить вы соглашаетесь с{" "}
                    <a
                      href={`/privacy-policy`}
                      target={`_blank`}
                      className={`${Styles.customlink}`}
                    >
                      политикой конфиденциальности
                    </a>
                    .
                  </div>
                </Form>
              </>
            )
          }
        </Formik>
      </div>
    </>
  );
};

export default WebsitesPage;
