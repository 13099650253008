// extracted by mini-css-extract-plugin
export var animicon = "websites-module--animicon--a2970";
export var block = "websites-module--block--35432";
export var customlink = "websites-module--customlink--a2b75";
export var field = "websites-module--field--eaeaa";
export var firstblock = "websites-module--firstblock--e088d";
export var mainlayer = "websites-module--mainlayer--ddaa2";
export var policylink = "websites-module--policylink--aa46b";
export var reverse = "websites-module--reverse--41271";
export var secondheader = "websites-module--secondheader--61d1e";
export var sendbutton = "websites-module--sendbutton--d8017";
export var workwrap = "websites-module--workwrap--73586";
export var wrapper = "websites-module--wrapper--3ab2a";